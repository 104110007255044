
import { Options, Vue } from "vue-class-component";
// Chart
import { BarChart, BarChartOptions, BoxSeriesData } from "@toast-ui/chart";
import { IState } from "src/interfaces/state.interface";
import { IStateExpropriationsByYear } from "src/interfaces/state-expropriations-by-year.interface";
import { IExpropriation } from "src/interfaces/expropriation.interface";
import {
  BoxSeriesDataType,
  BoxSeriesInput,
} from "@toast-ui/chart/types/options";

@Options({
  props: {
    states: Array,
  },
})
export default class FactsParties extends Vue {
  states!: IState[];
  chart!: BarChart;
  totalsByState: { [key: string]: number } = {};
  // Collection of state totals to be assigned to the federal ministry
  totalsByStateForFederalMinistry: { [key: string]: number } = {};

  parties = ["Union (CDU/CSU)", "Bündnis 90/Grüne", "SPD", "FDP", "Die Linke"];
  chartData = {
    categories: this.parties,
    series: [],
  } as BoxSeriesData;

  mounted(): void {
    this.setTotalsByState();
    this.setSeriesData();
    this.initChart();
  }

  unmounted(): void {
    this.chart.destroy();
  }

  private setTotalsByState(): void {
    if (this.states) {
      this.states.forEach((state: IState) => {
        const stateName = state.name;
        let expropriationByState: { [key: string]: number } = {};
        const expropriations = state.expropriationsByYear
          .filter((expropriationsByYear: IStateExpropriationsByYear) =>
            expropriationsByYear ? expropriationsByYear.year === 2020 : false
          )
          .flatMap(
            (expropriationsByYear: IStateExpropriationsByYear) =>
              expropriationsByYear.expropriations
          )
          .reduce((total: number, currentExpropriation: IExpropriation) => {
            if (
              currentExpropriation &&
              !!currentExpropriation.amount &&
              currentExpropriation.amount > 0
            ) {
              return (total += currentExpropriation.amount);
            } else {
              return total;
            }
          }, 0);
        expropriationByState[`${stateName}`] = expropriations;
        this.totalsByState = { ...this.totalsByState, ...expropriationByState };
      });
    }
  }

  /**
   * Creates an Array containing an object per State
   * which has the state's name and the total amount of that states expropriations
   * assigned to every party's index according to the `this.parties` list
   */
  private setSeriesData(): void {
    this.states.forEach((state: IState) => {
      const dataArray = [] as number[];
      let stateObj = { name: state.name, data: dataArray };
      state.expropriationsByYear
        .filter(
          (expropriations: IStateExpropriationsByYear) =>
            expropriations.year === 2020
        )
        .forEach((expropriations: IStateExpropriationsByYear) => {
          if (expropriations && expropriations.ministryOfTransport) {
            for (let i = 0; i < this.parties.length; i++) {
              const total = this.totalsByState[state.name];
              const affectedStreets = expropriations.affected
                ? expropriations.affected.length
                : 0;
              const affectedAutobahnen = expropriations.affected
                ? expropriations.affected.filter((street: string) =>
                    street.toLowerCase().startsWith("a")
                  ).length
                : 0;
              const affectedBundesstrassen = expropriations.affected
                ? expropriations.affected.filter((street: string) =>
                    street.toLowerCase().startsWith("b")
                  ).length
                : 0;

              // If *all* affected streets were Autobahnen
              // they belong to the federal government.
              // On federal level the ministry of transportation is responsible
              // hence the amount counts for the Union (CDU/CSU)
              if (
                affectedStreets > 0 &&
                affectedStreets === affectedAutobahnen &&
                !!this.totalsByStateForFederalMinistry
              ) {
                this.totalsByStateForFederalMinistry[state.name] = total;
              }
              // If *all* affected streets were Bundesstraßen
              // they belong to the state's governement.
              // Since there is no way to know for sure which ministry is responsible there
              // we fully account them to each coalition party
              else if (
                affectedStreets > 0 &&
                affectedStreets === affectedBundesstrassen &&
                expropriations.ministryOfTransport === this.parties[i]
              ) {
                stateObj.data[i] = total;
              }
              // If there are both affected Autobahnen and Bundesstraßen
              // we cannot know how many expropriations were made in the scope of which street.
              // So we distribute the total expropriations count depending on the amount of Autobahnen
              else if (
                affectedStreets > 0 &&
                affectedAutobahnen > 0 &&
                affectedBundesstrassen > 0 &&
                expropriations.ministryOfTransport === this.parties[i]
              ) {
                // If more then half of the affected streets were Bundesstraßen
                // we count only 1 expropriation per Autobahn (conservative calculation)
                // and the rest of the expropriations is assigned to the responsible
                // ministry of transport running party
                if (affectedBundesstrassen > affectedAutobahnen) {
                  this.totalsByStateForFederalMinistry[state.name] =
                    affectedAutobahnen; // 1 expropriation per Autobahn
                  stateObj.data[i] = total - affectedAutobahnen;
                }
                // If more then half of the affected streets were Autobahnen
                // we count only 1 expropriation per Bundesstraße (assigned to the ministry of transport party)
                // and the rest of the expropriations is assigned to the federal ministry
                else if (affectedAutobahnen > affectedBundesstrassen) {
                  stateObj.data[i] = affectedBundesstrassen; // 1 expropriation per Bundestraße
                  this.totalsByStateForFederalMinistry[state.name] =
                    total - affectedBundesstrassen;
                }
                // If each amount of both street types is identical
                // we distribute both equally to the federal and the state ministry
                // with assigning mathematical overflow to the state ministry
                // This is only the case in Thüringen (no need for complex calculations)
                else {
                  const equalShare = total / affectedStreets;
                  this.totalsByStateForFederalMinistry[state.name] = equalShare;
                  stateObj.data[i] = equalShare;
                }
              }
            }
          }
        });
      this.chartData.series = [...this.chartData.series, stateObj];
    });

    // Sum up all state totals to be assigned to the federal ministry
    const federalTotals = Object.values(
      this.totalsByStateForFederalMinistry
    ).reduce((accumulator, current) => (accumulator += current));

    // Remove all states that have no expropriations at all
    // OR had only expropriations to be assigned to the federal ministry
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.chartData.series = this.chartData.series.filter((stateObj: any) => {
      return (
        stateObj.data &&
        stateObj.data.length &&
        stateObj.data.reduce((a: number, b: number) => a + b) > 0
      );
    }) as BoxSeriesInput<BoxSeriesDataType>[];

    // Final chart series data
    this.chartData.series = [
      {
        name: "Bundesebene (Autobahnen)",
        data: [federalTotals, 0, 0, 0, 0],
      },
      ...this.chartData.series,
    ];
  }

  private initChart(): void {
    const el = document.getElementById("bar-chart") as HTMLElement;
    const data = this.chartData;
    const options = {
      chart: {
        width: "auto",
        height: "auto",
      },
      series: {
        stack: {
          type: "normal",
        },
        dataLabels: {
          visible: true,
          formatter: (value) => `${value && value > 0 ? value : ""}`,
          anchor: "center",
          stackTotal: {
            visible: true,
            formatter: (value) => `Gesamt ${value}`,
          },
        },
      },
      responsive: {
        animation: {
          duration: 600,
        },
        rules: [
          {
            condition: ({ width: w }) => {
              return w <= 768;
            },
            options: {
              legend: {
                align: "bottom",
              },
            },
          },
          {
            condition: ({ width: w }) => {
              return w < 690;
            },
            options: {
              series: {
                dataLabels: {
                  stackTotal: {
                    visible: false,
                  },
                },
              },
            },
          },
          {
            condition: ({ width: w }) => {
              return w <= 380;
            },
            options: {
              exportMenu: {
                visible: false,
              },
              series: {
                dataLabels: {
                  visible: false,
                },
              },
            },
          },
        ],
      },
      theme: {
        series: {
          useSeriesColor: true,
          dataLabels: {
            useSeriesColor: false,
          },
        },
      },
      usageStatistics: false,
    } as BarChartOptions;
    this.chart = new BarChart({ el, data, options });
  }
}
